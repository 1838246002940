import React from 'react';
import { Box, Text, Link, IconButton, Stack, Flex, Image, useBreakpointValue, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLink, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ContactCard = () => {
    const { colorMode } = useColorMode();
    const isDarkMode = colorMode === "dark";
    const boxWidth = useBreakpointValue({ base: '90%', md: 'md' });
    const padding = useBreakpointValue({ base: 4, md: 8 });

    return (
        <Flex align="center" justify="center" height="100vh" bg="gray.800" color="black">
            <Box
                bg="white"
                p={padding}
                rounded="md"
                boxShadow="lg"
                maxW={boxWidth}
                textAlign="center"
            >
                <Image src={!isDarkMode ? "https://i.postimg.cc/8CjQ1sZL/blanco-recorte.png" : 'https://i.postimg.cc/g2MbF3HY/azul-recorte.png'} width='200px' alt="Igneo Logo" mx="auto" mb={6} />
                <Text fontSize="2xl" fontWeight="bold" mb={2}>
                    Sadowski Guillermo
                </Text>
                <Text fontSize="md" color="gray.500" mb={4}>
                    Gerente
                </Text>

                <Stack spacing={3} mb={6}>
                    <Flex align="center" justify="center" >
                        <FontAwesomeIcon icon={faPhone} style={{ marginRight: '8px' }} />
                        <Link href="tel:1132679693">1132679693</Link>
                    </Flex>
                    <Flex align="center" justify="center">
                        <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
                        <Link href="mailto:info@igneo.com.ar">info@igneo.com.ar</Link>
                    </Flex>
                    <Flex align="center" justify="center">
                        <FontAwesomeIcon icon={faLink} style={{ marginRight: '8px' }} />
                        <Link href="http://igneo.com.ar" isExternal target='_blank'>
                            igneo.com.ar
                        </Link>
                    </Flex>
                </Stack>

                <Stack spacing={3} mb={6}>
                    <Flex align="center" justify="center">
                        <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '8px' }} />
                        <Text color="gray.500">
                            Beazley, Pompeya, CABA 1437, Argentina
                        </Text>
                    </Flex>
                </Stack>

                <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Social Media
                </Text>
                <Flex justify="center" mb={4}>
                    <IconButton
                        as="a"
                        href="https://www.instagram.com/igneosrl/"
                        icon={<FontAwesomeIcon icon={faInstagram} />}
                        aria-label="Instagram"
                        variant="outline"
                        colorScheme="pink"
                        isRound
                        target='_blank'
                    />

                    <IconButton
                        as="a"
                        href="https://wa.me/5491132679693"
                        icon={<FontAwesomeIcon icon={faWhatsapp} />}
                        aria-label="Instagram"
                        variant="outline"
                        colorScheme="green"
                        ml={4}
                        isRound
                        target='_blank'
                    />
                </Flex>
            </Box>
        </Flex>
    );
};

export default ContactCard;
