import React, { useState } from 'react';
import {
    Box,
    Text,
    Image,
    VStack,
    Heading,
    Container,
    SimpleGrid,
    Card,
    CardBody,
    CardHeader,
    Button,
    Input,
    Textarea,
    useColorMode,
    Spacer,
    FormControl,
    FormLabel,
    useToast,
} from '@chakra-ui/react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { getDatabase, push, ref } from 'firebase/database';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Inicio = () => {
    const ruta = "Igneo/"
    const toast = useToast();
    const [formFields, setFormFields] = useState({
        clientName: "",
        clientPhone: "",
        clientEmail: "",
        clientMessage: ""
    });

    const handleInputChange = (e, index, key) => {
        const { name, value } = e.target;
        setFormFields({ ...formFields, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const db = getDatabase();
        const messagesRef = ref(db, ruta + 'contactMessages');

        try {
            await push(messagesRef, {
                ...formFields,
                timestamp: new Date().toISOString(),
                visto: false,
                contestado: false
            });
            toast({
                title: 'Mensaje enviado.',
                description: 'Tu mensaje ha sido enviado con éxito.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
            setFormFields({
                clientName: '',
                clientPhone: '',
                clientEmail: '',
                clientMessage: '',
            });
        } catch (error) {
            toast({
                title: 'Error al enviar el mensaje.',
                description: 'Hubo un problema al enviar tu mensaje. Inténtalo de nuevo más tarde.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error('Error al enviar el mensaje:', error);
        }
    };

    const { colorMode, toggleColorMode } = useColorMode();
    const isDarkMode = colorMode === "dark";

    return (
        <Box>
            {/* Slider Section */}
            <Box as="section" bg="gray.800" color="white" height="calc(100vh - 200px)">
                <AutoplaySlider
                    bullets={false}
                    organicArrows={false}
                    play
                    cancelOnInteraction={false}
                    interval={4000}
                    style={{ width: '100%', height: '100%' }}
                >
                    <div data-src="https://wallpapercave.com/wp/wp4041839.jpg">
                        <Text position="absolute" top={5} left={5} bg="blackAlpha.600" p={2} fontSize="xl">
                            Paneles Solares
                        </Text>
                        <Text position="absolute" bottom={5} left={5} bg="blackAlpha.600" p={2}>
                            Nuestros paneles solares de alta eficiencia garantizan una mayor captación de energía y durabilidad.
                        </Text>
                    </div>
                    <div data-src="https://wallpapercave.com/wp/wp11738578.jpg">
                        <Text position="absolute" top={5} right={5} bg="blackAlpha.600" p={2} fontSize="xl">
                            Baterías
                        </Text>
                        <Text position="absolute" bottom={5} right={5} bg="blackAlpha.600" p={2}>
                            Las baterías de ciclo profundo que ofrecemos aseguran almacenamiento eficiente de energía para su uso continuo.
                        </Text>
                    </div>
                    <div data-src="https://www.geesol.com/wp-content/uploads/2020/12/inversor-solar-que-es-funciones.jpg">
                        <Text position="absolute" top={5} left={5} bg="blackAlpha.600" p={2} fontSize="xl">
                            Inversores
                        </Text>
                        <Text position="absolute" bottom={5} left={5} bg="blackAlpha.600" p={2}>
                            Nuestros inversores convierten la energía solar en electricidad utilizable de manera eficiente y fiable.
                        </Text>
                    </div>
                </AutoplaySlider>
            </Box>

            {/* About Us Section */}
            <Box as="section" py={10}>
                <Container maxW="container.xl">
                    <Heading as="h2" mb={6}>
                        Sobre Nosotros
                    </Heading>
                    <Text>
                        Somos una empresa dedicada a ofrecer soluciones de energía solar de alta calidad. Proveemos una amplia gama de productos, incluyendo paneles solares, baterías y sistemas completos de energía renovable. Nuestro objetivo es promover el uso de energías limpias y ayudar a nuestros clientes a reducir su huella de carbono.
                    </Text>
                </Container>
            </Box>

            {/* Advantages and Disadvantages Section */}
            <Box as="section" py={10} bg="blackAlpha.600">
                <Container maxW="container.xl">
                    <Heading as="h2" mb={6} color="white">
                        Ventajas y Necesidades
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        <Card bg="gray.900">
                            <CardHeader color="white">
                                <Heading size="md">Ventajas</Heading>
                            </CardHeader>
                            <CardBody color="white">
                                <VStack align="start">
                                    <Text>- Energía limpia y renovable</Text>
                                    <Text>- Reducción de costos de electricidad a largo plazo</Text>
                                    <Text>- Baja huella de carbono</Text>
                                    <Text>- Independencia energética</Text>
                                </VStack>
                            </CardBody>
                        </Card>
                        <Card bg="gray.900">
                            <CardHeader color="white">
                                <Heading size="md">Necesidades</Heading>
                            </CardHeader>
                            <CardBody color="white">
                                <VStack align="start">
                                    <Text>- Inversión inicial alta</Text>
                                    <Text>- Dependencia del clima</Text>
                                    <Text>- Espacio amplio para instalación</Text>
                                    <Text>- Mantenimiento regular</Text>
                                </VStack>
                            </CardBody>
                        </Card>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Contact Section */}
            <Box as="section" py={10}>
                <Container maxW="container.xl">
                    <Heading as="h2" mb={6}>
                        Contáctanos
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        {/*  <ReCaptcha sitekey="TU_SITE_KEY" action="submit" verifyCallback={verifyCallback} /> */}
                        <VStack spacing={4} align="start">
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                                <FormControl id="clientName" mb={4} isRequired>
                                    <FormLabel>Nombre:</FormLabel>
                                    <Input type="text" name="clientName" value={formFields.clientName} onChange={handleInputChange} placeholder='Nombre' />
                                </FormControl>
                                <FormControl id="clientName" mb={4} isRequired>
                                    <FormLabel>Telefono:</FormLabel>
                                    <Input type="number" name="clientPhone" value={formFields.clientPhone} onChange={handleInputChange} placeholder='Telefono' />
                                </FormControl>
                                <FormControl id="clientName" mb={4} isRequired>
                                    <FormLabel>Email:</FormLabel>
                                    <Input type="email" name="clientEmail" value={formFields.clientEmail} onChange={handleInputChange} placeholder='Email' />
                                </FormControl>
                                <FormControl id="clientMessage" mb={4} isRequired>
                                    <FormLabel>Mensaje:</FormLabel>
                                    <Textarea rows={6} name="clientMessage" value={formFields.clientMessage} onChange={handleInputChange} placeholder='Mensaje' />
                                </FormControl>
                                <Button type="submit" colorScheme="teal">Enviar</Button>
                            </form>
                        </VStack>
                        <Box>
                            <Image src={isDarkMode ? "https://i.postimg.cc/8CjQ1sZL/blanco-recorte.png" : 'https://i.postimg.cc/g2MbF3HY/azul-recorte.png'} fallbackSrc="https://via.placeholder.com/200" width='200px' />
                            <Spacer />
                            <Text>
                                Si tiene alguna pregunta o necesita más información sobre nuestros productos de energía solar, no dude en contactarnos a través de este formulario. Estamos aquí para ayudar a encontrar la mejor solución para sus necesidades energéticas.
                            </Text>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>
        </Box>
    );
};

export default Inicio;
