/**
 * @dependencias
 */
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ChakraProvider, extendTheme, useColorMode } from "@chakra-ui/react"

/**
 * @paginas
 */
import SignUp from "../Pages/SignUp/SignUp"
import Login from "../Pages/Login/Login"
import Budgets from "./../Pages/Presupuestos/Budgets"
import Header from "./../Pages/Header/Header"
import Products from "../Pages/Productos/Products"
import Inicio from "../Pages/Inicio/Inicio"
import BudgetViewer from "../Pages/VisualizadorPresupuesto/BudgetViewer"
import Messages from "../Pages/Mensajes/Messages"
import ContactCard from "../Pages/CartaPresentacion/ContactCard"

// Tema Oscuro
const darkTheme = extendTheme({
    colors: {
        mode: {
            dark: {
                bgPrimary: "#1a202c",
                bgSecondary: "#2d3748",
                textPrimary: "#ffffff",
                textSecondary: "#cbd5e0"
            }
        }
    }
});

const RoutesWeb = () => {
    return (
        <ChakraProvider theme={darkTheme}>
            <ColorModeSetter />
            <Router>
                {window.location.pathname !== "/card/guillermo" && <Header />}
                <Routes>
                    <Route path="" element={<Inicio />} />
                    <Route path="login" element={<Login />} />
                    {/*  <Route path="/register" element={<SignUp />} /> */} {/* Ruta para la página de registro */}
                    <Route path="products" element={<Products />} /> {/* Ruta para agregar productos */}
                    <Route path="budgets">
                        <Route path="" element={<Budgets />} />
                        <Route path=":key" element={<BudgetViewer />} />
                    </Route>
                    <Route path="messages" element={<Messages />} />
                    <Route path="card/guillermo" element={<ContactCard />} />
                </Routes>
            </Router>
        </ChakraProvider>
    );
};

const ColorModeSetter = () => {
    const { colorMode, toggleColorMode } = useColorMode();

    React.useEffect(() => {
        toggleColorMode("dark");
    }, []);

    return null;
};

export default RoutesWeb;
