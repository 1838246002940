import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, onValue, ref, remove } from 'firebase/database';
import { firebase } from '../../firebaseConfig';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, IconButton, Tooltip, Spacer, Heading, Stack, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, useToast, Flex, Spinner } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import AddProductList from '../AgregarProducto/AddProductList';
import TablaProductos from './TablaProductos';
import AddMovementProduct from '../AgregarProducto/AddMovmentProduct';
import TableMovements from './TableMovements';
import moment from 'moment';

const Products = () => {
    const ruta = "Igneo/"
    const [productos, setProductos] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showMovementModal, setShowMovementModal] = useState(false)
    const [productoAEditar, setProductoAEditar] = useState(null)
    const [movimientosAVer, setMovimientosAVer] = useState(null)
    const [user, setUser] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const toast = useToast();
    const auth = getAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate("/")
            } else {
                setUser(user)
            }
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        const db = getDatabase(firebase)
        const budgetRef = ref(db, ruta + "productos")
        onValue(budgetRef, (snapshot) => {
            try {
                if (snapshot.exists()) {
                    const productData = snapshot.val()

                    // Obtener las claves de los objetos
                    const keys = Object.keys(productData)

                    // Obtener los presupuestos con las claves
                    const productList = keys.map(key => ({
                        key: key,
                        ...productData[key]
                    }))

                    setProductos(productList)
                } else {
                    setProductos([]);
                }
            } catch {
                toast({
                    title: "Error",
                    description: "Hubo un error al obtener los datos.",
                    status: "error",
                    duration: 3000,
                    isClosable: false,
                })
            } finally {
                setLoading(false)
            }
        })
    }, [])

    const handleCloseModal = () => {
        setShowAddModal(false)
        setProductoAEditar(null)
        setShowMovementModal(false)
        setMovimientosAVer(null)
    }

    const onDeleteProducto = (productoId) => {
        setDeleteId(productoId)
        setIsOpen(true)
    }

    const onEditProducto = (key, producto) => {
        setProductoAEditar(producto, key)
        setShowAddModal(true)
    }

    const seeMovements = (key, producto) => {
        setMovimientosAVer(producto, key)
        setShowMovementModal(true)
    }

    const handleConfirmDelete = () => {
        const db = getDatabase(firebase)
        const budgetRef = ref(db, `${ruta}productos/${deleteId}`)

        remove(budgetRef).then(() => {
            toast({
                title: "Eliminado",
                description: "El producto se eliminó correctamente",
                status: "success",
                duration: 3000,
                isClosable: false,
            })
            setIsOpen(false)
        }).catch((error) => {
            console.error("Error al eliminar el producto", error);
            toast({
                title: "Error",
                description: "Hubo un error al eliminar el producto.",
                status: "error",
                duration: 3000,
                isClosable: false,
            })
        })
    };

    const formatNumber = (number) => {
        if (isNaN(number)) {
            return "0.00"
        }

        return Number(number).toLocaleString("es-AR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }

    const exportarProductosMovimientos = (products) => {
        // Crear un objeto para agrupar productos por nombre
        const productosAgrupados = products.reduce((acc, producto) => {
            const categoriaMayusculas = producto.categoria.toUpperCase();
            if (!acc[categoriaMayusculas]) {
                acc[categoriaMayusculas] = [];
            }
            acc[categoriaMayusculas].push(producto);
            return acc;
        }, {});

        // Crear una hoja de cálculo para cada producto
        const hojasDeCalculo = Object.keys(productosAgrupados).map((nombreProducto) => {
            // Crear datos de la hoja de cálculo con encabezados
            const datosHoja = [
                ["Fecha", "Tipo", "Nombre", "Precio de Compra", "Cantidad", "Precio de Venta", "Cotizacion"]
            ];

            productosAgrupados[nombreProducto].forEach(producto => {
                // Verificar si movimientos es un objeto
                const movimientos = typeof producto.movimientos === 'object' && producto.movimientos !== null ? producto.movimientos : {};
                Object.keys(movimientos).forEach(tipoMovimiento => {
                    const movimiento = movimientos[tipoMovimiento];
                    const fecha = moment(movimiento.fechaUnix * 1000).format("DD/MM/YYYY")
                    if (movimiento.tipo !== "edicion") {
                        datosHoja.push([
                            fecha,
                            movimiento.tipo.toUpperCase(),
                            producto.nombre,
                            movimiento.precioCompra,
                            movimiento.cantidad,
                            movimiento.precioVenta,
                            movimiento.cotizacion
                        ]);
                    }
                });
            });

            // Crear una hoja de cálculo
            const hoja = XLSX.utils.aoa_to_sheet(datosHoja);

            // Ajustar el ancho de las columnas
            const columnas = [
                { wch: 12 }, // Ancho de la columna para Fecha
                { wch: 10 }, // Ancho de la columna para Tipo
                { wch: 20 }, // Ancho de la columna para Nombre
                { wch: 15 }, // Ancho de la columna para Precio de Compra
                { wch: 10 }, // Ancho de la columna para Cantidad
                { wch: 15 }, // Ancho de la columna para Precio de Venta
                { wch: 15 }, // Ancho de la columna para Cotizacion
            ];

            // Establecer el ancho de las columnas
            hoja['!cols'] = columnas;

            return { nombre: nombreProducto, hoja };
        });

        // Crear un libro de Excel
        const libro = XLSX.utils.book_new();
        hojasDeCalculo.forEach(({ nombre, hoja }) => {
            XLSX.utils.book_append_sheet(libro, hoja, nombre);
        });

        // Exportar el libro de Excel
        const excelBuffer = XLSX.write(libro, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'productos_movimientos.xlsx');
    };

    return (
        <Box p={6} maxW="1633px" mx="auto">
            <Stack direction={['column', 'row']} spacing='24px' m={4}>
                <Heading as="h2" mb={6}>Lista de Productos</Heading>
                <Spacer />
                <Tooltip label='Volver'>
                    <IconButton
                        colorScheme="yellow"
                        isRound
                        as={Link}
                        to="/"
                        aria-label='Volver'
                        icon={<FontAwesomeIcon icon={faCaretLeft} />}
                    />
                </Tooltip>
            </Stack>
            {loading ?
                <Flex width="100%" direction="column" justifyContent="center" alignContent="center" alignItems="center">
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>
                :
                <Box align="center" display="flex">
                    <Button colorScheme='teal' variant='outline' onClick={() => setShowAddModal(true)}>
                        Agregar Producto
                    </Button>

                    <Spacer />

                    <Button colorScheme='teal' variant='outline' onClick={() => exportarProductosMovimientos(productos)}>
                        Exportar XLSX
                    </Button>

                    <Spacer />

                    <Button colorScheme='teal' variant='outline' onClick={() => setShowMovementModal(true)} isDisabled={productos.length <= 0}>
                        Realizar movimiento
                    </Button>
                </Box>
            }

            {productos && productos.length > 0 ?
                <TablaProductos productos={productos} onEditProducto={onEditProducto} onDeleteProducto={onDeleteProducto} formatNumber={formatNumber} seeMovements={seeMovements} />
                : (
                    !loading &&
                    <Box mt={6}>
                        <Heading textAlign={"center"} mb={6}>No existen productos</Heading>
                    </Box>
                )}

            <Modal isOpen={showAddModal} onClose={handleCloseModal} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <AddProductList aEditar={productoAEditar} onClose={handleCloseModal} usuario={user} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={showMovementModal} onClose={handleCloseModal} size="4xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {movimientosAVer ?
                            <TableMovements movimientos={Object.values(movimientosAVer.movimientos)} formatNumber={formatNumber} moneda={movimientosAVer.moneda} seeMovements={seeMovements} />
                            :
                            <AddMovementProduct aEditar={productoAEditar} onClose={handleCloseModal} productos={productos} usuario={user} />
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Eliminar Producto
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        ¿Estás seguro de que deseas eliminar este producto? No podrás deshacer esta acción.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={() => setIsOpen(false)}>
                            Cancelar
                        </Button>
                        <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                            Eliminar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            {/*  BOTON PARA AGREGAR PRODUCTO  */}
            <Tooltip label="Agregar Producto" placement="left">
                <Box
                    position="fixed"
                    bottom={8}
                    right={8}
                    zIndex="999"
                >
                    <IconButton
                        isRound
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        colorScheme="teal"
                        size="lg"
                        aria-label="Agregar Producto"
                        onClick={() => setShowAddModal(true)}
                    />
                </Box>
            </Tooltip>
        </Box>
    );
};

export default Products;
