import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, FormControl, FormLabel, Input, Heading, useToast, Stack, Select, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import { getDatabase, ref, push, update, get, child } from 'firebase/database';
import { firebase } from "../../firebaseConfig";
import moment from 'moment';

const AddMovementProduct = ({ aEditar, onClose, productos, usuario }) => {
    const ruta = "Igneo/";
    const toast = useToast();
    const [productDate, setProductDate] = useState(aEditar ? aEditar.fecha : moment().format("YYYY-MM-DD"));
    const [product, setProduct] = useState('');
    const [productPriceBuy, setProductPriceBuy] = useState(aEditar ? aEditar.precioCompra : '');
    const [productPrice, setProductPrice] = useState(aEditar ? aEditar.precioCompra : '');
    const [productPriceSell, setProductPriceSell] = useState(aEditar ? aEditar.precioVenta : '');
    const [stock, setStock] = useState(aEditar ? aEditar.cantidad : '');
    const [productType, setProductType] = useState(aEditar ? aEditar.categoria : '');
    const [condicion, setCondicion] = useState({ min: 0, max: 0 });
    const [verDialog, setVerDialog] = useState(false)

    const calcularGanancia = (precioCompra, precioVenta, cantidad) => {
        let resultado = 0
        if (precioVenta, precioCompra, cantidad) {
            resultado = (precioVenta * cantidad) - (precioCompra * cantidad);
        }
        return resultado
    }

    const calcularMaximaCantidad = (productoElegido, tipoDeMovimiento) => {
        let condiciones = { min: 1, max: 0 }
        if (tipoDeMovimiento === "compra") {
            condiciones = { min: 1 }
        } else if (tipoDeMovimiento === "venta") {
            const productoSeleccionado = productos.find((producto) => producto.id === productoElegido)
            condiciones = { min: 1, max: Number(productoSeleccionado.cantidad) }
        }
        setCondicion(condiciones)
    }

    const handleSubmit = async () => {

        if (!product || !productPriceBuy || !productPriceSell || !productType || !stock) {
            toast({
                title: 'Error',
                description: 'Por favor completa todos los campos',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const productoSeleccionado = productos.find((producto) => producto.id === product);

        if (!productoSeleccionado) {
            toast({
                title: 'Error',
                description: 'Producto no encontrado',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const db = getDatabase(firebase);
        const productRef = ref(db, `${ruta}productos/${productoSeleccionado.key}`);
        const newMovementKey = push(ref(db, `${ruta}productos/${productoSeleccionado.key}/movimientos`)).key;

        const movimiento = {
            fechaUnix: moment(productDate).unix(),
            id: uuidv4(),
            tipo: productType,
            cantidad: stock,
            usuario: usuario.email,
            precioCompra: Number(productPriceBuy).toFixed(2),
            precioVenta: Number(productPriceSell).toFixed(2),
            rateGanancia: calcularGanancia(productPriceBuy, productPriceSell, stock).toFixed(2),
            cotizacion: Number(productPrice),
            totalCambio: productType === "venta" ? Number(productPrice) * Number(productPriceSell) : Number(productPrice) * Number(productPriceBuy)
        };

        let updatedCantidad = Number(productoSeleccionado.cantidad);
        let updates = {};

        if (productType === "compra") {
            updatedCantidad += Number(stock);

            // Recuperar todos los movimientos de compra del producto seleccionado
            const movimientosSnapshot = await get(child(ref(db), `${ruta}productos/${productoSeleccionado.key}/movimientos`));
            let totalCompra = 0;
            let cantidadTotalCompra = 0;

            if (movimientosSnapshot.exists()) {
                const movimientos = movimientosSnapshot.val();
                Object.values(movimientos).forEach(mov => {
                    if (mov.tipo === "compra") {
                        totalCompra += mov.precioCompra * mov.cantidad;
                        cantidadTotalCompra += Number(mov.cantidad);
                    }
                });
            }

            // Calcular el nuevo precio de compra promedio
            totalCompra += productPriceBuy * Number(stock);
            cantidadTotalCompra += Number(stock);
            const nuevoPrecioCompraPromedio = totalCompra / cantidadTotalCompra;

            updates[`precioCompra`] = Number(nuevoPrecioCompraPromedio).toFixed(2);
            updates[`precioVenta`] = Number(productPriceSell).toFixed(2);
        } else if (productType === "venta") {
            updatedCantidad -= Number(stock);
            updates[`precioCompra`] = Number(productPriceBuy).toFixed(2);
            updates[`precioVenta`] = Number(productPriceSell).toFixed(2);
        }

        updates[`cantidad`] = updatedCantidad;
        updates[`movimientos/${newMovementKey}`] = movimiento;

        update(productRef, updates).then(() => {
            toast({
                title: productType === "venta" ? "Venta Añadida" : "Compra Añadida",
                description: 'El movimiento se añadió correctamente',
                status: 'success',
                duration: 3000
            });
            setProduct('')
            setProductPriceBuy('')
            setProductPriceSell('')
            setStock('')
            setProductType('')
            setVerDialog(false)
        }).catch((error) => {
            console.error('Error al agregar el movimiento:', error);
            toast({
                title: 'Error',
                description: 'Ocurrió un error al agregar el movimiento. Por favor inténtalo de nuevo.',
                status: 'error',
                duration: 3000
            });
        });
    };

    const showDialog = (e) => {
        e.preventDefault();
        setVerDialog(true)
    }

    useEffect(() => {
        if (product && productType) {
            const productoSeleccionado = productos.find((producto) => producto.id === product)
            calcularMaximaCantidad(product, productType)
            setProductPriceBuy(productoSeleccionado.precioCompra)
            setProductPriceSell(productoSeleccionado.precioVenta)
        } else {
            setProductPriceBuy("")
            setProductPriceSell("")
        }
    }, [product, productType])


    return (
        <Box p={6} maxW="800px" mx="auto">
            <>
                <Stack direction={['column', 'row']} spacing='24px' m={4} justifyContent="center">
                    <Heading as="h5" mr={4}>Agregar Movimiento</Heading>
                </Stack>
                <form onSubmit={showDialog}>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Fecha:</FormLabel>
                        <Input value={productDate} onChange={(e) => setProductDate(e.target.value)} placeholder='Selecciona una fecha' type='date' />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Producto:</FormLabel>
                        <Select
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            defaultValue=""
                        >
                            <option value=""><n>Seleccione</n></option>
                            {productos.map((product) => (
                                <option key={product.id} value={product.id}>{product.categoria.toUpperCase()} - {product.nombre}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Tipo:</FormLabel>
                        <Select
                            value={productType}
                            onChange={(e) => setProductType(e.target.value)}
                            defaultValue="usd"
                        >
                            <option value="">Seleccionar</option>
                            <option value="compra">Compra</option>
                            <option value="venta">Venta</option>
                        </Select>
                    </FormControl>
                    <FormControl mb={4} isRequired>
                        <FormLabel>Cantidad:</FormLabel>
                        <Input type="number" value={stock} onChange={(e) => setStock(e.target.value)} placeholder="Cantidad" isDisabled={aEditar} max={condicion.max} min={condicion.min} />
                    </FormControl>
                    <FormControl mb={4} isRequired>
                        <FormLabel>Precio Compra:</FormLabel>
                        <Input type="number" value={productPriceBuy} onChange={(e) => setProductPriceBuy(e.target.value)} placeholder="Precio" isDisabled={aEditar} min={0} step={0.01} />
                    </FormControl>
                    <FormControl mb={4} isRequired>
                        <FormLabel>Precio Venta:</FormLabel>
                        <Input type="number" value={productPriceSell} onChange={(e) => setProductPriceSell(e.target.value)} placeholder="Precio" min={0} step={0.01} />
                    </FormControl>
                    <FormControl mb={4} isRequired>
                        <FormLabel>Cotizacion:</FormLabel>
                        <Input type="number" value={productPrice} onChange={(e) => setProductPrice(e.target.value)} placeholder="Cotizacion" min={0} step={0.01} />
                    </FormControl>
                    <Button type="submit" colorScheme="blue" textAlign="center">Guardar Movimiento</Button>
                </form>

                <AlertDialog isOpen={verDialog} onClose={() => setVerDialog(false)}>
                    <AlertDialogOverlay />
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Guardar Movimiento
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Verifica que los datos estén ingresados correctamente, no podrás deshacer esta acción.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={() => setVerDialog(false)}>
                                Cancelar
                            </Button>
                            <Button colorScheme="green" onClick={handleSubmit} ml={3}>
                                Guardar
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </>
        </Box>
    );
};

export default AddMovementProduct;
