import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { Box, Button, Input, Stack, Heading, FormControl, FormErrorMessage, InputRightElement, InputGroup, Spacer, Tooltip, IconButton } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
    const auth = getAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log("Inicio de sesión exitoso", userCredential);
            navigate("/"); // Redirigir a la página principal después de iniciar sesión
        } catch (error) {
            setError(error.message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                navigate("/");
            }
        });

        return () => unsubscribe();
    }, []);


    return (
        <Box p={6} maxW="400px" mx="auto">
            <Stack direction={['column', 'row']} spacing='24px' mb={6}>
                <Heading as="h2" mb={6}>Iniciar Sesión</Heading>
                <Spacer />
                <Tooltip label='Volver'>
                    <IconButton
                        as={Link}
                        to="/"
                        colorScheme="yellow"
                        isRound
                        aria-label='Volver'
                        icon={<FontAwesomeIcon icon={faCaretLeft} />}
                    />
                </Tooltip>
            </Stack>
            <form onSubmit={handleSignIn}>
                <Stack spacing={4}>
                    <FormControl id="email" isRequired isInvalid={error}>
                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Correo electrónico" />
                    </FormControl>
                    <FormControl id="password" isRequired isInvalid={error}>
                        <InputGroup>
                            <Input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Contraseña"
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {error && <FormErrorMessage mt={4}>{error}</FormErrorMessage>}
                    </FormControl>
                    <Button type="submit" colorScheme="blue">Iniciar Sesión</Button>
                </Stack>
            </form>
        </Box>
    );
};

export default Login;
