import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { firebase } from '../../firebaseConfig';
import { getDatabase, onValue, ref, remove } from 'firebase/database';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, TableContainer, Table, Thead, Tr, Th, Tbody, Td, IconButton, Tooltip, Spacer, Heading, Stack, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Text, useToast, Flex, Spinner } from '@chakra-ui/react';
import BudgetViewer from '../VisualizadorPresupuesto/BudgetViewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faEye, faPlus, faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import Budget from '../Formulario/Budget';
import { getAuth } from 'firebase/auth';

const Budgets = () => {
    const ruta = "Igneo/"
    const [presupuestos, setPresupuestos] = useState(null)
    const [showPDFModal, setShowPDFModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [loading, setLoading] = useState(true);
    const [presu, setPresu] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const toast = useToast();
    const auth = getAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate("/")
            }
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        const db = getDatabase(firebase);
        const budgetRef = ref(db, ruta + "presupuestos");

        onValue(budgetRef, (snapshot) => {
            try {

                if (snapshot.exists()) {
                    const productData = snapshot.val();

                    // Obtener las claves de los objetos
                    const keys = Object.keys(productData);

                    // Obtener los presupuestos con las claves
                    const productList = keys.map(key => ({
                        key: key,
                        ...productData[key]
                    }));

                    setPresupuestos(productList);
                } else {
                    setPresupuestos([]);
                }
            } catch {
                toast({
                    title: "Error",
                    description: "Hubo un error al obtener los datos.",
                    status: "error",
                    duration: 3000,
                    isClosable: false,
                });
            } finally {
                setLoading(false)
            }
        });
    }, []);

    const handleClosePDFModal = () => {
        setShowPDFModal(false)
        setPresu(null)
    }

    const onViewPresupuesto = (presupuesto) => {
        setPresu(presupuesto)
        setShowPDFModal(true)
    }

    const onDeletePresupuesto = (presupuestoId) => {
        setDeleteId(presupuestoId)
        setIsOpen(true)
    };

    const handleConfirmDelete = () => {
        const db = getDatabase(firebase);
        const budgetRef = ref(db, `${ruta}presupuestos/${deleteId}`);

        remove(budgetRef).then(() => {
            toast({
                title: "Eliminado",
                description: "El presupuesto se eliminó correctamente",
                status: "success",
                duration: 3000,
                isClosable: false,
            });
            setIsOpen(false)
        }).catch((error) => {
            console.error("Error al eliminar el presupuesto", error);
            toast({
                title: "Error",
                description: "Hubo un error al eliminar el presupuesto.",
                status: "error",
                duration: 3000,
                isClosable: false,
            });
        });
    };

    const formatNumber = (number) => {
        if (isNaN(number)) {
            return "0.00";
        }

        return Number(number).toLocaleString("es-AR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const handleCloseAddModal = () => {
        setShowAddModal(false)
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(`${window.location}/${text}`).then(() => {
            toast({
                title: 'Texto copiado',
                description: 'El texto ha sido copiado al portapapeles.',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        }).catch((err) => {
            console.error('Error al copiar texto al portapapeles', err);
            toast({
                title: 'Error',
                description: 'Hubo un error al copiar el texto.',
                status: 'error',
                duration: 2000,
                isClosable: true,
            });
        });
    };


    return (
        <Box p={6} maxW="800px" mx="auto">
            <Stack direction={['column', 'row']} spacing='24px' m={4}>
                <Heading as="h2" mb={6}>Lista de Presupuestos</Heading>
                <Spacer />
                <Tooltip label='Volver'>
                    <IconButton
                        colorScheme="yellow"
                        isRound
                        as={Link}
                        to="/"
                        aria-label='Volver'
                        icon={<FontAwesomeIcon icon={faCaretLeft} />}
                    />
                </Tooltip>
            </Stack>
            {loading &&
                <Flex width="100%" direction="column" justifyContent="center" alignContent="center" alignItems="center">
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>
            }
            {presupuestos && presupuestos.length > 0 ?
                <TableContainer>
                    <Table variant='striped' colorScheme='teal'>
                        <Thead>
                            <Tr>
                                <Th>Presupuesto</Th>
                                <Th>Fecha</Th>
                                <Th>Cliente</Th>
                                <Th isNumeric>Total</Th>
                                <Th isNumeric>Accion</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {presupuestos.map((presupuesto, index) => (
                                <Tr key={index}>
                                    <Td>#{presupuesto.numero}</Td>
                                    <Td>{moment(presupuesto.fecha * 1000).format("DD/MM/YYYY")}</Td>
                                    <Td>{presupuesto.cliente.nombre} {presupuesto.cliente.apellido}</Td>
                                    <Td isNumeric>{presupuesto.moneda === "usd" ? presupuesto.moneda.toUpperCase() : "$"} {formatNumber(presupuesto.totalPresupuesto)}</Td>
                                    <Td isNumeric>
                                        <Tooltip label="Copiar Link">
                                            <IconButton
                                                onClick={() => copyToClipboard(presupuesto.key)}
                                                isRound
                                                colorScheme='blue'
                                                variant='outline'
                                                size='xs'
                                                aria-label={`copiar`}
                                                icon={<FontAwesomeIcon icon={faCopy} />}
                                            />
                                        </Tooltip>
                                        <Tooltip label="Ver Presupuesto">
                                            <IconButton
                                                onClick={() => onViewPresupuesto(presupuesto)}
                                                isRound
                                                colorScheme='teal'
                                                variant='outline'
                                                aria-label={`ver`}
                                                size='xs'
                                                m={2}
                                                icon={<FontAwesomeIcon icon={faEye} />}
                                            />
                                        </Tooltip>
                                        <Tooltip label="Eliminar Presupuesto">
                                            <IconButton
                                                onClick={() => onDeletePresupuesto(presupuesto.key)}
                                                isRound
                                                colorScheme='red'
                                                variant='outline'
                                                aria-label={`eliminar`}
                                                size='xs'
                                                icon={<FontAwesomeIcon icon={faTrash} />}
                                            />
                                        </Tooltip>

                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                : (
                    !loading &&
                    <>
                        <Text textAlign={"center"} mb={6}>No existen presupuestos</Text>
                    </>
                )}
            <Modal isOpen={showPDFModal} onClose={handleClosePDFModal} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <BudgetViewer budgetData={presu} style={{ display: "none" }} display="none" />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Modal isOpen={showAddModal} onClose={handleCloseAddModal} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody mt={9}>
                        <Budget />
                    </ModalBody>
                </ModalContent>
            </Modal>

            <AlertDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Eliminar Presupuesto
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        ¿Estás seguro de que deseas eliminar este presupuesto? No podrás deshacer esta acción.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={() => setIsOpen(false)}>
                            Cancelar
                        </Button>
                        <Button colorScheme="red" onClick={handleConfirmDelete} ml={3}>
                            Eliminar
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>

            <Tooltip label="Agregar Presupuesto" placement="left">
                <Box
                    position="fixed"
                    bottom={8}
                    right={8}
                    zIndex="999"
                >
                    <IconButton
                        isRound
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        colorScheme="teal"
                        size="lg"
                        aria-label="Agregar Presupuesto"
                        onClick={() => setShowAddModal(true)}
                    />
                </Box>
            </Tooltip>
        </Box>
    );
};

export default Budgets;
