import React, { useEffect, useState } from 'react';
import { Box, Heading, Spacer, Button, useColorMode, Tooltip, IconButton, Stack, Divider, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, useDisclosure, useMediaQuery, Image, Menu, MenuButton, MenuList, MenuItem, Avatar, Badge, Text } from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faMoon, faRightFromBracket, faSun } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { get, getDatabase, onValue, ref } from 'firebase/database';

const Header = () => {
    const ruta = "Igneo/"
    const auth = getAuth();
    const [user, setUser] = useState(null);
    const { colorMode, toggleColorMode } = useColorMode();
    const isDarkMode = colorMode === "dark";
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isAdmin, setIsAdmin] = useState(false);
    const [mensajeSinLeer, setMensajeSinLeer] = useState(0)
    const isMobile = useMediaQuery('(min-width: 800px)')
    const navigate = useNavigate()

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        if (user) {
            const db = getDatabase();
            const rootRef = ref(db, "users");
            const uid = user.uid;

            get(rootRef).then((res) => {
                const data = res.val();
                setIsAdmin(data[uid].isAdmin);
                localStorage.setItem("esAdmin", data[uid].isAdmin);
            }).catch((error) => {
                console.error("Error al comunicarse al servidor: ", error);
            });
        } else {
            setIsAdmin(false);
            localStorage.setItem("esAdmin", false);
        }
    }, [user]);

    useEffect(() => {
        const db = getDatabase();
        const budgetRef = ref(db, ruta + "contactMessages");
        if (user) {
            onValue(budgetRef, (snapshot) => {
                if (snapshot.exists()) {
                    const mensajes = snapshot.val();
                    const items = Object.values(mensajes)
                    const itemsNoLeidos = items.filter(item => item.visto === false)
                    setMensajeSinLeer(itemsNoLeidos.length)
                } else {
                    setMensajeSinLeer(0);
                }
            }, (error) => {
                console.error(error);
            });
        }

    }, [isAdmin])

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            navigate("/"); // Redirigir a la página principal después de cerrar sesión
        } catch (error) {
            console.error("Error al cerrar sesión", error);
        }
    };

    return (
        <Box p={2} boxShadow="md">
            <Stack direction={[isMobile ? 'row' : 'column', 'row']} spacing='24px' justifyContent="center" m={4}>
                <Heading size="md">
                    <Button as={Link} to="/" variant="link">
                        <Image src={isDarkMode ? "https://i.postimg.cc/8CjQ1sZL/blanco-recorte.png" : 'https://i.postimg.cc/g2MbF3HY/azul-recorte.png'} fallbackSrc="https://via.placeholder.com/200" width='200px' />
                    </Button>
                </Heading>
                <Spacer />
                <IconButton
                    display={{ base: "flex", md: "none" }}
                    onClick={onOpen}
                    isRound
                    icon={<FontAwesomeIcon icon={faBars} />}
                    aria-label="Menu"
                />
                <Stack direction="row" spacing="4" display={{ base: "none", md: "flex" }}>

                    <Button as={Link} to="/">Inicio</Button>

                    {user &&
                        <>
                            <Button as={Link} to="/budgets">Presupuestos</Button>
                            <Button as={Link} to="/products">Productos</Button>
                        </>
                    }

                    <Menu>
                        <MenuButton>
                            <Avatar name={user ? user.email.toUpperCase() : 'Usuario'} size='md' />
                        </MenuButton>
                        <MenuList zIndex={999}>
                            {!user ?
                                <MenuItem as={Link} to="/login">Iniciar Sesion</MenuItem>
                                :
                                <MenuItem as={Button} onClick={handleSignOut} rightIcon={<FontAwesomeIcon icon={faRightFromBracket} />}>Cerrar Sesion</MenuItem>
                            }
                        </MenuList>
                    </Menu>

                    {isAdmin &&
                        <Box position='relative'>
                            <Button
                                onClick={() => navigate("messages")}
                                isRound
                                variant='outline'
                                aria-label={`Notificaciones`}
                                size='md'
                                leftIcon={<FontAwesomeIcon icon={faBell} shake={mensajeSinLeer > 0} />}
                            >
                                {mensajeSinLeer}
                            </Button>
                        </Box>
                    }

                    <Spacer />

                    <Divider orientation='vertical' />
                    <Spacer />

                    <Tooltip label={`Modo ${colorMode === "light" ? "Claro" : "Oscuro"}`}>
                        <IconButton
                            onClick={toggleColorMode}
                            isRound
                            colorScheme='teal'
                            variant='outline'
                            aria-label={`Modo ${colorMode === "light" ? "claro" : "oscuro"}`}
                            icon={<FontAwesomeIcon icon={colorMode === "light" ? faMoon : faSun} />}
                        />
                    </Tooltip>
                </Stack>
                <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Menú</DrawerHeader>
                        <DrawerBody>
                            <Stack spacing="4">
                                <Button as={Link} to="/" onClick={onClose}>Inicio</Button>

                                {user &&
                                    <>
                                        <Button as={Link} to="/budgets" onClick={onClose}>Presupuestos</Button>
                                        <Button as={Link} to="/products" onClick={onClose}>Productos</Button>
                                    </>
                                }

                                <Menu>
                                    <MenuButton>
                                        <Avatar name='Usuario' />
                                    </MenuButton>
                                    <MenuList zIndex={999}>
                                        {!user ?
                                            <MenuItem as={Link} to="/login">Iniciar Sesion</MenuItem>
                                            :
                                            <MenuItem as={Button} onClick={handleSignOut} rightIcon={<FontAwesomeIcon icon={faRightFromBracket} />}>Cerrar Sesion</MenuItem>
                                        }
                                    </MenuList>
                                </Menu>
                                {isAdmin &&
                                    <IconButton
                                        onClick={toggleColorMode}
                                        isRound
                                        colorScheme='teal'
                                        variant='outline'
                                        aria-label={`Notificaciones`}
                                        icon={<FontAwesomeIcon icon={faBell} />}
                                    />
                                }
                            </Stack>
                        </DrawerBody>
                        <DrawerFooter justifyContent="center">
                            <Stack direction={['row', 'row']} spacing='24px' justifyContent="center" m={4}>
                                <IconButton
                                    onClick={toggleColorMode}
                                    isRound
                                    colorScheme='teal'
                                    variant='outline'
                                    aria-label={`Modo ${colorMode === "light" ? "claro" : "oscuro"}`}
                                    icon={<FontAwesomeIcon icon={colorMode === "light" ? faMoon : faSun} />}
                                />
                                {user &&
                                    <IconButton
                                        onClick={handleSignOut}
                                        colorScheme="red"
                                        isRound
                                        aria-label='Cerrar Sesion'
                                        icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                                    />
                                }
                            </Stack>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
            </Stack>
        </Box >
    );
};

export default Header;
