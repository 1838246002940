import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Heading, FormControl, FormLabel, Input, Textarea, Stack, IconButton, useMediaQuery, useToast, Spacer, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Tooltip, Divider, AbsoluteCenter, Select, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { getDatabase, ref, update, push, onValue } from "firebase/database";
import { firebase } from "../../firebaseConfig";
import moment from "moment/moment";
import BudgetViewer from "../VisualizadorPresupuesto/BudgetViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Budget = () => {
    const ruta = "Igneo/"
    const [budgetNumber, setBudgetNumber] = useState(0);
    const [budgetView, setBudgetView] = useState(null);
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [showExportButton, setShowExportButton] = useState(false);
    const [products, setProducts] = useState([]);
    const [formFields, setFormFields] = useState({
        clientName: "",
        clientLastName: "",
        clientPhone: "",
        clientAddress: "",
        clientEmail: "",
        salesman: "",
        discount: 0,
        items: []
    });

    const toast = useToast();
    const contentRef = useRef(null);
    useEffect(() => {
        const db = getDatabase(firebase);
        const budgetRef = ref(db, ruta + "presupuestos");
        const productsRef = ref(db, ruta + "productos");

        onValue(budgetRef, (snapshot) => {
            if (snapshot.exists()) {
                const totalBudgets = Object.keys(snapshot.val());
                setBudgetNumber(totalBudgets.length + 1);
            } else {
                setBudgetNumber(1)
            }
        });

        onValue(productsRef, (snapshot) => {
            if (snapshot.exists()) {
                const productData = snapshot.val();

                const productList = Object.values(productData);

                setProducts(productList);
            } else {
                setProducts([]);
            }
        });
    }, []);


    const handleQuantityChange = (newValue, index) => {
        const newItems = [...formFields.items];
        newItems[index].cantidad = newValue;
        setFormFields({ ...formFields, items: newItems });
    };

    const handleInputChange = (e, index, key) => {
        setShowExportButton(false);
        const { name, value } = e.target;
        if (key === "items") {
            const newItems = [...formFields.items];
            newItems[index][name] = value;
            setFormFields({ ...formFields, items: newItems });
        } else {
            setFormFields({ ...formFields, [name]: value });
        }
    };

    const handleAddItem = () => {
        setFormFields({ ...formFields, items: [...formFields.items, { nombre: "", precio: "", cantidad: 1 }] });
    };

    const handleRemoveItem = (index) => {
        const newItems = [...formFields.items];
        newItems.splice(index, 1);
        setFormFields({ ...formFields, items: newItems });
    };

    const handleProductSelect = (selectedProductId, index) => {

        const selectedProduct = products.find(product => product.id === selectedProductId);
        if (!selectedProduct) {
            if (selectedProductId === "manual") {
                const newItems = [...formFields.items];
                newItems[index] = { prod: "manual", cantidad: 1, precio: "" };
                setFormFields(prevState => ({
                    ...prevState,
                    items: newItems
                }));
                return;
            } else {
                return;
            }
        }

        const selectedItem = formFields.items[index];
        if (selectedItem && selectedItem.id === selectedProductId) {
            // Si el producto ya está seleccionado, aumentar su cantidad
            selectedItem.cantidad += 1;
        } else {
            // Si no está seleccionado, crear uno nuevo
            const newItems = [...formFields.items];
            newItems[index] = { id: selectedProduct.id, nombre: selectedProduct.nombre, precio: selectedProduct.precio, cantidad: 1, moneda: selectedProduct.moneda };
            setFormFields(prevState => ({
                ...prevState,
                items: newItems
            }));
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const currentDateUnix = moment().unix();

        let totalPresupuesto = 0;
        const itemsWithTotal = formFields.items.map((item) => {
            const totalItem = Number(item.precio) * Number(item.cantidad);
            totalPresupuesto += totalItem;
            return { ...item, total: totalItem };
        });

        if (formFields.items.length <= 0) {
            toast({
                title: "Error",
                description: "Debe tener al menos 1 producto.",
                status: "error",
                duration: 3000,
                isClosable: false,
            });
            return;
        }

        const totalConDescuento = formFields.discount > 0 ? totalPresupuesto - (totalPresupuesto * formFields.discount / 100) : totalPresupuesto;

        const db = getDatabase(firebase);
        const budgetRef = ref(db, ruta + "presupuestos");
        const newBudgetKey = push(budgetRef).key;

        const newBudget = {
            numero: String(budgetNumber).padStart(7, "0"),
            fecha: currentDateUnix,
            cliente: {
                nombre: formFields.clientName,
                apellido: formFields.clientLastName,
                telefono: formFields.clientPhone,
                direccion: formFields.clientAddress,
                email: formFields.clientEmail
            },
            vendedor: formFields.salesman,
            items: itemsWithTotal,
            descuento: formFields.discount / 100,
            descuentoPrecio: (totalPresupuesto * formFields.discount / 100),
            moneda: "usd",
            totalPresupuesto: totalConDescuento
        };

        setBudgetView(newBudget)

        update(ref(db, `${ruta}presupuestos/${newBudgetKey}`), newBudget)
            .then(() => {
                toast({
                    title: "Guardado",
                    description: "Los datos se guardaron correctamente",
                    status: "success",
                    duration: 3000,
                    isClosable: false,
                });

                setShowExportButton(true);

                setFormFields({
                    clientName: "",
                    clientLastName: "",
                    clientPhone: "",
                    clientAddress: "",
                    clientEmail: "",
                    salesman: "",
                    discount: 0,
                    items: [{ nombre: "", precio: "", cantidad: 1 }]
                })
            })
            .catch((error) => {
                console.error("Error al guardar los datos del presupuesto:", error);
                toast({
                    title: "Error",
                    description: "Los datos no se pudieron guardar.",
                    status: "error",
                    duration: 3000,
                    isClosable: false,
                });
            });
    };

    const handleOpenPDFModal = () => {
        setShowPDFModal(true);
    };

    const handleClosePDFModal = () => {
        setShowPDFModal(false);
    };

    return (
        <Box p={6} borderWidth="1px" borderRadius="lg">
            <Heading as="h2" mb={6}>{budgetNumber && "#" + String(budgetNumber).padStart(7, "0")} </Heading>
            <form onSubmit={handleSubmit}>
                <FormControl id="clientName" mb={4} isRequired>
                    <FormLabel>Nombre del cliente:</FormLabel>
                    <Input type="text" name="clientName" value={formFields.clientName} onChange={handleInputChange} placeholder='Nombre' />
                </FormControl>
                <FormControl id="clientLastName" mb={4} isRequired>
                    <FormLabel>Apellido del cliente:</FormLabel>
                    <Input type="text" name="clientLastName" value={formFields.clientLastName} onChange={handleInputChange} placeholder='Apellido' />
                </FormControl>
                <FormControl id="clientPhone" mb={4} isRequired>
                    <FormLabel>Teléfono del cliente:</FormLabel>
                    <Input type="tel" name="clientPhone" value={formFields.clientPhone} onChange={handleInputChange} placeholder='+5491121351234' />
                </FormControl>
                <FormControl id="clientAddress" mb={4} isRequired>
                    <FormLabel>Dirección del cliente:</FormLabel>
                    <Textarea name="clientAddress" value={formFields.clientAddress} onChange={handleInputChange} placeholder='Av Corrientes 2789, CABA' />
                </FormControl>
                <FormControl id="clientEmail" mb={4}>
                    <FormLabel>Email del cliente:</FormLabel>
                    <Input type="email" name="clientEmail" value={formFields.clientEmail} onChange={handleInputChange} placeholder='email@correo.com' />
                </FormControl>
                <FormControl id="salesman" mb={4} isRequired>
                    <FormLabel>Asesor:</FormLabel>
                    <Input type="text" name="salesman" value={formFields.salesman} onChange={handleInputChange} placeholder='Nombre de asesor/a' />
                </FormControl>

                <Box position='relative' padding='10'>
                    <Divider />
                    <AbsoluteCenter px='4'>
                        <Heading as="h3">Productos</Heading>
                    </AbsoluteCenter>
                </Box>
                {formFields.items.map((item, index) => (
                    <Stack key={index} direction={['column', 'row']} spacing={4} mb={4} alignItems={isMobile ? 'flex-start' : 'flex-end'}>
                        <FormControl isRequired>
                            <FormLabel>Producto:</FormLabel>
                            <Box position="relative">
                                {products.length > 0 && item.prod !== 'manual' ? (
                                    <Select
                                        value={item.producto}
                                        onChange={(e) => handleProductSelect(e.target.value, index)}
                                        defaultValue=""
                                    >
                                        <option value=""><n>Seleccione</n></option>
                                        {products.map((product) => (
                                            <option key={product.id} value={product.id}>{product.nombre}</option>
                                        ))}
                                        <option value="manual">Manual</option>
                                    </Select>
                                ) : (
                                    <Input
                                        type="text"
                                        name="nombre"
                                        value={item.nombre}
                                        onChange={(e) => handleInputChange(e, index, "items")}
                                        placeholder='Producto'
                                    />
                                )}
                            </Box>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Cantidad:</FormLabel>
                            <NumberInput
                                value={item.cantidad}
                                onChange={(value) => handleQuantityChange(value, index)}
                                min={1}
                            >
                                <NumberInputField name="cantidad" />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Precio Unitario:</FormLabel>
                            <Input type="number" name="precio" value={item.precio} onChange={(e) => handleInputChange(e, index, "items")} placeholder='Precio Unitario' />
                        </FormControl>
                        <Tooltip label={formFields.items.length <= 1 ? "" : 'Eliminar'}>
                            <IconButton icon={<DeleteIcon />} isRound isDisabled={formFields.items.length <= 1} colorScheme="red" onClick={() => handleRemoveItem(index)} />
                        </Tooltip>
                    </Stack>
                ))}
                <Box textAlign="center">
                    <Tooltip label='Añadir'>
                        <IconButton icon={<FontAwesomeIcon icon={faPlus} />} isRound colorScheme="green" onClick={handleAddItem} alignContent="center" />
                    </Tooltip>
                </Box>
                <FormControl id="discount" mb={4}>
                    <FormLabel>Descuento (%):</FormLabel>
                    <Input type="number" name="discount" value={formFields.discount} onChange={handleInputChange} placeholder='10' />
                </FormControl>
                <Spacer />
                <Stack direction="row" spacing={4}>
                    {showExportButton ? (
                        <Stack direction="row" spacing={4}>
                            <Button onClick={handleOpenPDFModal} colorScheme="blue">Visualizar PDF</Button>
                        </Stack>
                    ) : (
                        <Stack direction="row" spacing={4}>
                            <Button type="submit" colorScheme="green">Guardar</Button>
                        </Stack>
                    )}
                </Stack>
            </form>
            <Modal isOpen={showPDFModal} onClose={handleClosePDFModal} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <BudgetViewer budgetData={budgetView} ref={contentRef} style={{ display: "none" }} display="none" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );

};

export default Budget;
