import React from "react";
import { Box, Stepper, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";


const TableMovements = ({ movimientos, formatNumber, moneda }) => {

    const totalMovimiento = (monto, cantidad) => {
        return (monto * cantidad)
    }

    const sumaTotal = movimientos.reduce((acc, movimiento) => {
        const total = movimiento.tipo === "venta"
            ? totalMovimiento(movimiento.precioVenta, movimiento.cantidad)
            : movimiento.tipo === "compra" && totalMovimiento(-movimiento.precioCompra, movimiento.cantidad);

        return acc + total;
    }, 0);

    return (
        <TableContainer mt={6} mb={4}>
            <Table variant='striped' colorScheme='teal' size="sm">
                <Thead>
                    <Tr>
                        <Th textAlign="center">Fecha</Th>
                        <Th textAlign="center">Tipo</Th>
                        <Th textAlign="center">Cantidad</Th>
                        <Th textAlign="center">Precio</Th>
                        <Th textAlign="center">Cotización</Th>
                        <Th textAlign="center">Total</Th>
                        <Th textAlign="center">Observación</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {movimientos.map((movimiento, index) => (
                        <Tr key={index}>
                            {/* FECHA */}
                            <Td textAlign="center">
                                {moment(movimiento.fechaUnix * 1000).format("DD/MM/YYYY")}
                            </Td>
                            {/* TIPO */}
                            <Td textAlign="center">
                                {movimiento.tipo.toUpperCase()}
                            </Td>
                            {/* CANTIDAD */}
                            <Td textAlign="center">
                                {movimiento.tipo === "edicion" ? "-" : movimiento.tipo === "venta" && "-"}{movimiento.tipo !== "edicion" && movimiento.cantidad}
                            </Td>
                            {/* PRECIO */}
                            <Td textAlign="center">
                                {movimiento.tipo !== "edicion" ?
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        {moneda.toUpperCase()} {movimiento.tipo === "venta" ? formatNumber(movimiento.precioVenta) : formatNumber(movimiento.precioCompra)}
                                        <Tooltip label={`El precio al cambio es $ ${formatNumber(movimiento.totalCambio)}`}>
                                            <FontAwesomeIcon icon={faCircleInfo} />
                                        </Tooltip>
                                    </Box>
                                    :
                                    "-"
                                }
                            </Td>
                            {/* COTIZACION */}
                            <Td textAlign="center">
                                {movimiento.tipo !== "edicion" ?
                                    "$ " + formatNumber(movimiento.cotizacion)
                                    :
                                    "-"
                                }
                            </Td>
                            {/* TOTAL */}
                            <Td textAlign="center">
                                {movimiento.tipo !== "edicion" ?
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        {moneda.toUpperCase()} {movimiento.tipo === "venta" ? formatNumber(totalMovimiento(movimiento.precioVenta, movimiento.cantidad)) : "-" + formatNumber(totalMovimiento(movimiento.precioCompra, movimiento.cantidad))}
                                        <Tooltip label={`El total al cambio es $ ${formatNumber(movimiento.totalCambio * movimiento.cantidad)}`}>
                                            <FontAwesomeIcon icon={faCircleInfo} />
                                        </Tooltip>
                                    </Box>
                                    :
                                    "-"
                                }
                            </Td>
                            {/* OBSERVACION */}
                            <Td textAlign="center">
                                {movimiento.observacion}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th>TOTAL</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        <Th isNumeric>{moneda.toUpperCase()} {formatNumber(sumaTotal)}</Th>
                        <Th></Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}

export default TableMovements