import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCiEQx6vip3eEFkGUDStrAymnBun3D8Uec",
    authDomain: "igneo-web.firebaseapp.com",
    databaseURL: "https://igneo-web-default-rtdb.firebaseio.com",
    projectId: "igneo-web",
    storageBucket: "igneo-web.appspot.com",
    messagingSenderId: "401689036131",
    appId: "1:401689036131:web:c88c41afe2f7121b5a3f56",
    measurementId: "G-4659GV1S8P"
};

const firebase = initializeApp(firebaseConfig);
getAnalytics(firebase);

export { firebase }; 
