import { Box, IconButton, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { faEye, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";


const TablaProductos = ({ productos, formatNumber, onEditProducto, onDeleteProducto, seeMovements }) => {

    const [totalesProductos, setTotalesProductos] = useState(null)

    const rateGanancia = (venta, compra, cantidad) => {
        let rate = ""
        rate = (venta * cantidad) - (compra * cantidad)
        return Number(rate)
    }

    const ratedeGananciaTotal = (productos) => {
        return productos.reduce((acc, producto) => {
            if (producto.categoria === 'cables') {
                acc.totalMetros += Number(producto.cantidad);
            } else {
                acc.totalUnidades += Number(producto.cantidad);
            }
            acc.totalRateDeGanancia += (producto.precioVenta * producto.cantidad) - (producto.precioCompra * producto.cantidad);
            return acc;
        }, {
            totalMetros: 0,
            totalUnidades: 0,
            totalRateDeGanancia: 0
        });
    };

    useEffect(() => {
        const totales = ratedeGananciaTotal(productos)
        setTotalesProductos(totales)
    }, [productos])

    
    return (
        <TableContainer maxH="calc(100vh - 200px)" overflowY="auto" mt={6}>
            <Table variant='striped' colorScheme='teal' size="sm">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Producto</Th>
                        <Th>Stock</Th>
                        <Th isNumeric>Precio Compra</Th>
                        <Th isNumeric>Precio Venta</Th>
                        <Th isNumeric>Rate Ganancia</Th>
                        <Th isNumeric>Accion</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {productos.map((producto, index) => (
                        <Tr key={index}>
                            <Td>#{index}</Td>
                            <Td>{producto.categoria.toUpperCase()} - {producto.nombre}</Td>
                            <Td>{producto.cantidad ? producto.cantidad + (producto.categoria === "cables" ? " M" : " U") : " - "}</Td>
                            <Td isNumeric>{producto.moneda.toUpperCase()} {formatNumber(producto.precioCompra)}</Td>
                            <Td isNumeric>{producto.moneda.toUpperCase()} {formatNumber(producto.precioVenta)}</Td>
                            <Td isNumeric>{producto.moneda.toUpperCase()} {formatNumber(rateGanancia(producto.precioVenta, producto.precioCompra, producto.cantidad))}</Td>
                            <Td isNumeric>
                                <Tooltip label="Ver Movimientos">
                                    <IconButton
                                        onClick={() => seeMovements(producto.key, producto)}
                                        isRound
                                        colorScheme='green'
                                        variant='outline'
                                        aria-label={`eliminar`}
                                        icon={<FontAwesomeIcon icon={faEye} />}
                                    />
                                </Tooltip>
                                <Tooltip label="Editar Producto">
                                    <IconButton
                                        onClick={() => onEditProducto(producto.key, producto)}
                                        isRound
                                        colorScheme='yellow'
                                        variant='outline'
                                        aria-label={`eliminar`}
                                        m={2}
                                        icon={<FontAwesomeIcon icon={faPencil} />}
                                    />
                                </Tooltip>
                                <Tooltip label="Eliminar Producto">
                                    <IconButton
                                        onClick={() => onDeleteProducto(producto.key)}
                                        isRound
                                        colorScheme='red'
                                        variant='outline'
                                        aria-label={`eliminar`}
                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                    />
                                </Tooltip>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
                <Tfoot>
                    <Tr verticalAlign="middle">
                        <Th>Total</Th>
                        <Th></Th>
                        <Th>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
                                <Text>
                                    Metros:  {totalesProductos?.totalMetros}
                                </Text>
                                <Text>
                                    Unidades:  {totalesProductos?.totalUnidades}
                                </Text>
                            </Box>

                        </Th>
                        <Th></Th>
                        <Th></Th>
                        <Th isNumeric>USD {Number(totalesProductos?.totalRateDeGanancia).toFixed(2)}</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}

export default TablaProductos