import React, { useEffect, useState } from "react";
import moment from "moment";
import { Flex, Text, Grid, GridItem, Image, Button, useColorMode, Link, Heading, Spinner, useToast, Spacer } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import { get, getDatabase, ref } from "firebase/database";
import { firebase } from "../../firebaseConfig";

const BudgetViewer = (params) => {
    const ruta = "Igneo/"
    const { key } = useParams();
    const { colorMode } = useColorMode();
    const isDarkMode = colorMode === "dark";
    const [budgetData, setBudgetData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const toast = useToast();

    useEffect(() => {
        if (key) {
            const getPresuData = async () => {
                const db = getDatabase(firebase);
                const budgetRef = ref(db, `${ruta}presupuestos/${key}`);
                try {
                    const snapshot = await get(budgetRef);
                    if (snapshot.exists()) {
                        setBudgetData(snapshot.val());
                    } else {
                        setError('No se encontró el presupuesto');
                    }
                } catch (err) {
                    console.error("ERROR: ", err);
                    setError('Error al obtener los datos del presupuesto');
                } finally {
                    setLoading(false);
                }
            };

            if (key) {
                getPresuData();
            } else {
                setLoading(false);
                setError('No se proporcionó una clave de presupuesto');
            }
        } else if (params.budgetData) {
            setBudgetData(params.budgetData)
            setLoading(false)
        }
    }, [key]);

    const exportToPDF = async () => {
        if (budgetData) {

            const input = document.getElementById("content");

            // Obtener las dimensiones del elemento en píxeles
            const width = input.offsetWidth;
            const height = input.offsetHeight;

            // Calcular el factor de escala para ajustar el contenido en una hoja A4
            let scaleFactor = 1; // Por defecto, no se cambia la escala


            // Calcular el tamaño de la página del documento
            const pageSize = [width, height + 83.01];

            // Calcular la escala necesaria para intentar ajustar el contenido en una sola página

            const pdf = new jsPDF(
                {
                    orientation: "p", // Orientación: retrato
                    unit: "px", // Unidad de medida: milímetros
                    format: pageSize // Formato: personalizado si es menor que A4, A4 si es más grande
                }
            )

            pdf.html(
                input,
                {
                    callback: (pdf) => {
                        pdf.backgroundColor = "#1a202c"
                        pdf.save(`Presupuesto #${budgetData.numero} - ${budgetData.cliente.nombre} ${budgetData.cliente.apellido}.pdf`);
                    },
                    html2canvas: {
                        scale: scaleFactor, // Escala del renderizado
                        windowWidth: width, // Ancho del elemento
                        windowHeight: height, // Alto del elemento
                        backgroundColor: isDarkMode ? "#1a202c" : "white"
                    },
                    backgroundColor: isDarkMode ? "#1a202c" : "white"
                },
            )
            toast({
                title: "Guardado",
                description: "El presupuesto se exporto correctamente.",
                status: "success",
                duration: 3000,
                isClosable: false,
            });
        } else {
            toast({
                title: "Error",
                description: "No existe presupuesto para exportar.",
                status: "error",
                duration: 3000,
                isClosable: false,
            });
        }
    };

    const formatNumber = (number) => {
        if (isNaN(number)) {
            return "0.00";
        }

        return Number(number).toLocaleString("es-AR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    return (
        <Flex
            alignItems="center"
            gap="6px"
            direction="column"
            minBlockSize="100vh"
        >
            <Flex justify="center" width={{ base: "100%", sm: "688px" }}>
                <Flex width={{ base: "90%", sm: "688px" }}>
                </Flex>
            </Flex>

            {/* Detalles del presupuesto */}
            <Flex
                id="content"
                position="static"
                display="flex"
                flexDirection="column"
                borderRadius="8px"
                padding={{ base: "24px 24px 0", sm: "32px 32px 0" }}
                alignItems="start"
                margin="0 auto 82px"
                width={{ base: "90%", sm: "688px" }}
            >
                {loading &&
                    <Flex width="100%" direction="column" justifyContent="center" alignContent="center" alignItems="center">
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Flex>
                }
                {budgetData &&
                    <>

                        <Flex width="100%" direction="column" gap={6}>
                            <Flex
                                direction={{ base: "column", sm: "row" }}
                                justify={{ base: "initial", sm: "space-between" }}
                                gap="30px"
                                lineHeight={{ base: "1.25", sm: "1.5" }}
                            >
                                {/* Número de presupuesto */}
                                <Flex direction="column">
                                    <GridItem
                                        fontSize={{
                                            base: "0.75rem",
                                            sm: "1rem",
                                        }}
                                        lineHeight="1.25"
                                        letterSpacing="-0.25px"
                                        display="flex"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <Text fontWeight="700">PRESUPUESTO</Text>
                                    </GridItem>
                                    <Text
                                        fontSize={{
                                            base: "0.75rem",
                                            sm: "1rem",
                                        }}
                                        lineHeight="1.25"
                                        letterSpacing="-0.25px"
                                        color="darkThemeGreyWhite"
                                        fontWeight="500"
                                    >
                                        #{budgetData.numero}
                                    </Text>
                                </Flex>
                                {/* Dirección de facturación */}
                                <Flex
                                    textAlign={{ base: "revert", sm: "right" }}
                                    fontSize="0.6875rem"
                                    fontWeight="500"
                                    lineHeight="1.6"
                                    color="darkThemeGreyWhite"
                                    width={{ base: "60%", sm: "fit-content" }}
                                    direction="column"
                                    letterSpacing="-0.23px"
                                >
                                    <Image src={isDarkMode ? "https://i.postimg.cc/8CjQ1sZL/blanco-recorte.png" : 'https://i.postimg.cc/g2MbF3HY/azul-recorte.png'} fallbackSrc="https://via.placeholder.com/200" width='200px' />
                                </Flex>
                            </Flex>

                            {/* Detalles de fechas y destinatario */}
                            <Flex
                                gap={{ base: "24px", sm: "90px" }}
                                direction={{ base: "column", sm: "row" }}
                            >
                                <Grid
                                    fontSize="0.75rem"
                                    lineHeight="1.25"
                                    letterSpacing="-0.25px"
                                    columnGap={{ base: "24px", sm: "90px" }}
                                    rowGap={6}
                                    templateColumns={"repeat(2,1fr)"}
                                >
                                    {/* Fecha del presupuesto */}
                                    <GridItem colSpan={1}>
                                        <Text
                                            marginBottom={{ base: "0px", sm: "12px" }}
                                            color="darkThemeGreyWhite"
                                            fontWeight="500"
                                        >
                                            Cliente
                                        </Text>
                                        <Text
                                            fontSize="0.9375rem"
                                            lineHeight="1.33"
                                            letterSpacing="-0.31px"
                                            fontWeight="700"
                                            marginBottom={{ base: "8px", sm: "12px" }}
                                        >
                                            {budgetData.cliente.nombre} {budgetData.cliente.apellido}
                                        </Text>
                                        <Flex
                                            fontSize="0.6875rem"
                                            fontWeight="500"
                                            lineHeight="1.6"
                                            color="darkThemeGreyWhite"
                                            direction="column"
                                        >
                                            {budgetData.cliente.telefono.length > 9 &&
                                                <Link href={`https://wa.me/${budgetData.cliente.telefono}`} isExternal>{budgetData.cliente.telefono} <ExternalLinkIcon mx='2px' /></Link>
                                            }
                                            <Link href={`https://www.google.com/maps/place/${budgetData.cliente.direccion}`} isExternal>{budgetData.cliente.direccion} <ExternalLinkIcon mx='2px' /></Link>
                                            {budgetData.cliente.email &&
                                                <Link href={`mailto:${budgetData.cliente.email}`} isExternal>{budgetData.cliente.email} <ExternalLinkIcon mx='2px' /></Link>
                                            }
                                        </Flex>
                                    </GridItem>
                                </Grid>

                                <Flex lineHeight="1.4" direction="column">
                                    {/* FECHA DE PRESUPUESTO */}
                                    <Text
                                        fontSize={{
                                            base: "0.75rem",
                                            sm: "1rem",
                                        }}
                                        lineHeight="1.25"
                                        letterSpacing="-0.25px"
                                        color="darkThemeGreyWhite"
                                        fontWeight="500"
                                    >
                                        {moment(budgetData.fecha * 1000).format("DD/MM/YYYY")}
                                    </Text>
                                    {/* NUMERO DE PRESUPUESTO */}
                                    <Text
                                        marginBottom={{ base: "0px", sm: "12px" }}
                                        color="darkThemeGreyWhite"
                                        fontWeight="500"
                                    >
                                        Asesor: {budgetData.vendedor}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                        {/* Detalles de los elementos */}
                        <Flex
                            direction="column"
                            margin="48px auto 32px"
                            width={{ base: "73%", sm: "624px" }}
                        >
                            <Flex
                                borderRadius="8px 8px 0 0"
                                alignItems="start"
                                color="darkThemeGrey"
                                margin="0 auto"
                                width="100%"
                                padding="24px 24px"
                                backgroundColor="rgb(163 163 163)"
                            >
                                <Grid width="100%" rowGap={1}>
                                    {/* Detalles de cada elemento */}
                                    <Grid templateColumns="repeat(12, 1fr)" color="darkThemeWhite" bg="bgMedium" rounded="8px 8px 0 0">
                                        <GridItem colSpan={4}>
                                            <Text
                                                fontSize="11px"
                                                color="darkThemeGreyWhite"
                                                fontWeight="500"
                                                lineHeight="1.636" mb={4}>
                                                Producto
                                            </Text>
                                        </GridItem>
                                        <GridItem colSpan={2} align="center">
                                            <Text
                                                fontSize="11px"
                                                color="darkThemeGreyWhite"
                                                fontWeight="500"
                                                lineHeight="1.636" mb={4}>
                                                Cantidad
                                            </Text>
                                        </GridItem>
                                        <GridItem colSpan={3} align="end">
                                            <Text
                                                fontSize="11px"
                                                color="darkThemeGreyWhite"
                                                fontWeight="500"
                                                lineHeight="1.636" mb={4}>
                                                Precio
                                            </Text>
                                        </GridItem>
                                        <GridItem colSpan={3} align="end">
                                            <Text
                                                fontSize="11px"
                                                color="darkThemeGreyWhite"
                                                fontWeight="500"
                                                lineHeight="1.636" mb={4}>
                                                Total
                                            </Text>
                                        </GridItem>
                                    </Grid>

                                    {Object.values(budgetData.items).map((valor, index) => (
                                        <Grid key={index} templateColumns="repeat(12, 1fr)" color="darkThemeWhite" bg="bgLight" rounded="8px" mb={2}>
                                            <GridItem colSpan={4}>
                                                <Text textStyle="h3" color="colorLight" mb={2}>
                                                    {valor.nombre}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={2} display="flex" alignItems="center" justifyContent="center">
                                                <Text textStyle="h3" mb={2}>
                                                    {valor.cantidad}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={3} display="flex" alignItems="center" justifyContent="flex-end">
                                                <Text textStyle="h3" mb={2}>
                                                    {`${valor.moneda === "usd" ? "USD" : "$"} ${formatNumber(valor.precio)}`}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={3} display="flex" alignItems="center" justifyContent="flex-end">
                                                <Text textStyle="h3" color="colorLight" mb={2}>
                                                    {`${valor.moneda === "usd" ? valor.moneda.toUpperCase() : "$"} ${formatNumber(valor.total)}`}
                                                </Text>
                                            </GridItem>
                                        </Grid>
                                    ))}

                                    {budgetData.descuento > 0 && (
                                        <Grid templateColumns="repeat(12, 1fr)" color="darkThemeWhite" bg="bgLight" rounded="8px" mb={2}>
                                            <GridItem colSpan={4}>
                                                <Text textStyle="h3" color="colorLight" mb={2}>
                                                    Descuento
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={2} display="flex" alignItems="center" justifyContent="center">
                                                <Text textStyle="h3" mb={2}>
                                                    -
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={3} display="flex" alignItems="center" justifyContent="flex-end">
                                                <Text textStyle="h3" mb={2}>
                                                    {budgetData.descuento * 100} %
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={3} display="flex" alignItems="center" justifyContent="flex-end">
                                                <Text textStyle="h3" color="colorLight" mb={2}>
                                                    {budgetData.moneda === "usd" ? budgetData.moneda.toUpperCase() : "$"} {formatNumber(budgetData.descuentoPrecio)}
                                                </Text>
                                            </GridItem>
                                        </Grid>
                                    )}
                                </Grid>
                            </Flex>
                            {/* Total */}
                            <Flex
                                width="100%"
                                borderRadius="0 0 8px 8px"
                                padding="24px"
                                height="80px"
                                marginBottom={6}
                                backgroundColor="rgb(88 111 122)"
                            >
                                <Flex width="100%" align="center" justify="space-between">
                                    <Text
                                        fontSize="11px"
                                        color="darkThemeGreyWhite"
                                        fontWeight="500"
                                        lineHeight="1.636"
                                    >
                                        Total
                                    </Text>
                                    <Text
                                        fontSize="1.25rem"
                                        color="darkThemeWhite"
                                        fontWeight="700"
                                        lineHeight="1.6"
                                    >

                                        {budgetData.moneda === "usd" ? budgetData.moneda.toUpperCase() : "$"} {formatNumber(budgetData.totalPresupuesto)}

                                    </Text>
                                </Flex>
                            </Flex>
                            {budgetData.moneda === "usd" ?
                                <Flex
                                    fontSize="0.6875rem"
                                    fontWeight="500"
                                    lineHeight="1.6"
                                    color="darkThemeGreyWhite"
                                    direction="column"
                                >
                                    <Text>*La cotizacion del dólar es el publicado por el BNA</Text>
                                </Flex>

                                :
                                ""
                            }
                        </Flex>
                        <Flex width="100%" direction="column" gap={6}>
                            <Flex
                                direction={{ base: "column", sm: "row" }}
                                justify={{ base: "initial", sm: "space-between" }}
                                gap="30px"
                                lineHeight={{ base: "1.25", sm: "1.5" }}
                            >
                            </Flex>

                            {/* Detalles de fechas y destinatario */}
                            <Flex
                                gap={{ base: "24px", sm: "90px" }}
                                direction={{ base: "column", sm: "row" }}
                            >
                                <Grid
                                    fontSize="0.75rem"
                                    lineHeight="1.25"
                                    letterSpacing="-0.25px"
                                    columnGap={{ base: "24px", sm: "90px" }}
                                    rowGap={6}
                                    templateColumns={"repeat(2,1fr)"}
                                >
                                    {/* Fecha del presupuesto */}
                                    <GridItem colSpan={1}>
                                        <Text
                                            marginBottom={{ base: "0px", sm: "12px" }}
                                            color="darkThemeGreyWhite"
                                            fontWeight="500"
                                        >
                                            Informacion de Pago
                                        </Text>
                                        <Text
                                            fontSize="0.9375rem"
                                            lineHeight="1.33"
                                            letterSpacing="-0.31px"
                                            fontWeight="700"
                                            marginBottom={{ base: "8px", sm: "12px" }}
                                        >
                                            Banco Galicia
                                        </Text>
                                        <Flex
                                            fontSize="0.6875rem"
                                            fontWeight="500"
                                            lineHeight="1.6"
                                            color="darkThemeGreyWhite"
                                            direction="column"
                                        >
                                            <Text>Nombre: JOSE PERALTA </Text>
                                            <Text>N°: 0031648813</Text>
                                            <Text>CBU: 00000121236454659</Text>
                                        </Flex>
                                    </GridItem>
                                </Grid>

                                <Flex lineHeight="1.4" direction="column" justifyContent="flex-end">
                                    <Spacer />
                                    {/* FECHA DE PRESUPUESTO */}
                                    <Text
                                        fontSize={{
                                            base: "0.75rem",
                                            sm: "1rem",
                                        }}
                                        lineHeight="1.25"
                                        letterSpacing="-0.25px"
                                        color="darkThemeGreyWhite"
                                        fontWeight="500"
                                    >
                                        Ingneo S.R.L.
                                    </Text>
                                    <Flex
                                        fontSize="0.6875rem"
                                        fontWeight="500"
                                        lineHeight="1.6"
                                        color="darkThemeGreyWhite"
                                        direction="column"
                                    >
                                        <Text>Dirección: Beazley 3976, C.A.B.A</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </>
                }
                {error &&
                    <Flex width="100%" direction="column" gap={6} textAlign="center">
                        <Heading>{error}</Heading>
                    </Flex>
                }
            </Flex>

            <Button colorScheme="green" onClick={exportToPDF} isDisabled={!budgetData}>
                Exportar
            </Button>

        </Flex >
    );
};

export default BudgetViewer;
