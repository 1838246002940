import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, FormControl, FormLabel, Input, Heading, useToast, Stack, Select } from '@chakra-ui/react';
import { getDatabase, ref, push, update } from 'firebase/database';
import { firebase } from "../../firebaseConfig";
import moment from 'moment';

const AddProductList = ({ aEditar, onClose, usuario }) => {
    const ruta = "Igneo/"
    const toast = useToast();
    const [productDate, setProductDate] = useState(aEditar ? moment(aEditar.fecha).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"))
    const [productName, setProductName] = useState(aEditar ? aEditar.nombre : '');
    const [productPriceBuy, setProductPriceBuy] = useState(aEditar ? aEditar.precioCompra : '');
    const [productPriceSell, setProductPriceSell] = useState(aEditar ? aEditar.precioVenta : '');
    const [productPrice, setProductPrice] = useState(aEditar ? aEditar.cotizacion : '');
    const [stock, setStock] = useState(aEditar ? aEditar.cantidad : '');
    const [productCoin, setProductCoin] = useState(aEditar ? aEditar.moneda : 'usd');
    const [productCategory, setProductCategory] = useState(aEditar ? aEditar.categoria : '');



    const calcularGanancia = (precioCompra, precioVenta, cantidad) => {
        let resultado = 0
        if (precioVenta, precioCompra, cantidad) {
            resultado = (precioVenta * cantidad) - (precioCompra * cantidad);
        }

        return Number(resultado)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Verifica que los campos no estén vacíos
        if (!productName || !productPriceBuy || !productPriceSell || !productCoin || !productCategory || !stock) {
            toast({
                title: 'Error',
                description: 'Por favor completa todos los campos',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        // Guardar el producto en la base de datos
        const db = getDatabase(firebase);
        const productsRef = ref(db, ruta + 'productos');
        let newProductKey = null;

        if (aEditar) {
            newProductKey = aEditar.key;
        } else {
            newProductKey = push(productsRef).key;
        }

        let observacion = '';
        if (aEditar) {
            const cambios = [];
            if (productName !== aEditar.nombre) cambios.push(`Nombre: ${aEditar.nombre} a ${productName}`);
            if (productCategory !== aEditar.categoria) cambios.push(`Categoría: ${aEditar.categoria.toUpperCase()} a ${productCategory.toUpperCase()}`);
            if (Number(productPriceSell).toFixed(2) !== aEditar.precioVenta) cambios.push(`Precio de Venta: ${aEditar.precioVenta} a ${Number(productPriceSell).toFixed(2)}`);
            observacion = cambios.length > 0 ? cambios.join(', ') : 'No se realizaron cambios significativos';
        }

        console.log("CAMMBIO : ", productPrice)

        // Crear el movimiento
        const movimiento = {
            fechaUnix: moment(productDate).unix(),
            id: uuidv4(),
            tipo: aEditar ? "edicion" : "compra",
            usuario: usuario.email,
            cantidad: stock,
            precioCompra: Number(productPriceBuy).toFixed(2),
            precioVenta: Number(productPriceSell).toFixed(2),
            rateGanancia: calcularGanancia(productPriceBuy, productPriceSell, stock).toFixed(2),
            cotizacion: aEditar ? 0 : Number(productPrice),
            totalCambio: aEditar ? 0 : Number(productPrice) * Number(productPriceBuy),
            observacion: aEditar ? observacion : 'Se creó el producto'
        };

        // Crear el nuevo producto
        const newProduct = {
            id: aEditar ? aEditar.id : uuidv4(), // Generar ID único
            nombre: productName,
            precioCompra: Number(productPriceBuy).toFixed(2),
            precioVenta: Number(productPriceSell).toFixed(2),
            cantidad: stock,
            creadoPor: usuario.email,
            moneda: productCoin,
            categoria: productCategory,
            movimientos: aEditar ? [...aEditar.movimientos, movimiento] : [movimiento] // Agregar movimiento de edición o compra
        };

        // Actualizar la base de datos
        update(ref(db, `${ruta}productos/${newProductKey}`), newProduct).then(() => {
            toast({
                title: aEditar ? 'Producto Editado' : 'Producto añadido',
                description: aEditar ? `Se editó correctamente el producto ${productName}` : `Se añadió correctamente el producto ${productName}`,
                status: 'success',
                duration: 3000
            });
            // Limpiar los campos después de agregar el producto
            setProductName('');
            setProductPrice('');
            setProductPriceBuy('');
            setProductPriceSell('');
            setStock('');
            setProductCategory('');
            setProductCoin('');
            // Cierra el Modal y elimina los datos del producto a Editar
            onClose();
        }).catch((error) => {
            console.error('Error al agregar el producto:', error);
            toast({
                title: 'Error',
                description: aEditar ? `Ocurrió un error al editar el producto ${productName}. Por favor inténtalo de nuevo.` : `Ocurrió un error al agregar el producto ${productName}. Por favor inténtalo de nuevo.`,
                status: 'error',
                duration: 3000
            });
        });
    };

    return (
        <Box p={6} maxW="800px" mx="auto">
            <>

                <Stack direction={['column', 'row']} spacing='24px' m={4} justifyContent="center">
                    <Heading as="h5" mr={4}>Agregar Producto</Heading>
                </Stack>

                <form onSubmit={handleSubmit}>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Fecha:</FormLabel>
                        <Input value={productDate} onChange={(e) => setProductDate(e.target.value)} placeholder='Selecciona una fecha' type='date' />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Nombre:</FormLabel>
                        <Input type="text" value={productName} onChange={(e) => setProductName(e.target.value)} placeholder="Nombre" />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Categoria:</FormLabel>
                        <Select
                            value={productCategory}
                            onChange={(e) => setProductCategory(e.target.value)}
                            defaultValue="usd"
                        >
                            <option value="">Seleccionar</option>
                            <option value="cables">Cables</option>
                            <option value="paneles">Paneles</option>
                            <option value="baterias">Baterias</option>
                            <option value="inversores">Inversores</option>
                            <option value="conectores">Conectores</option>
                            <option value="controladores">Controladores</option>
                            <option value="reguladores">Reguladores de Carga</option>
                            <option value="termotanques">Termotanques</option>
                            <option value="sensores">Sensores</option>
                            <option value="protecciones">Protecciones</option>
                            <option value="fusibles">Fusibles</option>
                            <option value="otros">Otros</option>
                        </Select>
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Cantidad:</FormLabel>
                        <Input type="number" value={stock} onChange={(e) => setStock(e.target.value)} placeholder="Cantidad" isDisabled={aEditar} />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Precio Compra:</FormLabel>
                        <Input type="number" value={productPriceBuy} onChange={(e) => setProductPriceBuy(e.target.value)} placeholder="Precio" isDisabled={aEditar} step={0.01} />
                    </FormControl>

                    <FormControl mb={4} isRequired>
                        <FormLabel>Precio Venta:</FormLabel>
                        <Input type="number" value={productPriceSell} onChange={(e) => setProductPriceSell(e.target.value)} placeholder="Precio" step={0.01} />
                    </FormControl>

                    {!aEditar && productCoin === "usd" &&
                        <FormControl mb={4} isRequired>
                            <FormLabel>Cotizacion:</FormLabel>
                            <Input type="number" value={productPrice} onChange={(e) => setProductPrice(e.target.value)} placeholder="Cotizacion" step={0.01} />
                        </FormControl>
                    }

                    <FormControl mb={4} isRequired>
                        <FormLabel>Moneda:</FormLabel>
                        <Select
                            value={productCoin}
                            onChange={(e) => setProductCoin(e.target.value)}
                            defaultValue="usd"
                            isDisabled={aEditar}
                        >
                            <option value="">Seleccione</option>
                            <option value="usd">Dólar Estadounidense</option>
                            <option value="ars" disabled>Pesos Argentinos</option>
                        </Select>
                    </FormControl>

                    <Button type="submit" colorScheme="blue" textAlign="center">{aEditar ? "Guardar Cambios" : "Agregar Producto"}</Button>

                </form>

            </>
        </Box>
    );
};

export default AddProductList;
