import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { firebase } from '../../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { getDatabase, onValue, ref, update } from 'firebase/database';
import { Box, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, TableContainer, Table, Thead, Tr, Th, Tbody, Td, IconButton, Tooltip, Spacer, Heading, Stack, Flex, Spinner, Text, useToast, Grid } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCheck, faCheckDouble, faCircleCheck, faEnvelope, faEye, faMinus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const Messages = () => {
    const ruta = "Igneo/"
    const [user, setUser] = useState(null)
    const [messages, setMessages] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showAddModal, setShowAddModal] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false);
    const [mensaje, setMensaje] = useState(null)
    const toast = useToast()
    const auth = getAuth()
    const navigate = useNavigate()

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate("/")
            } else {
                setUser(user)
            }
        });

        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        const esAdmin = localStorage.getItem("esAdmin")
        if (user) {
            if (esAdmin) {
                setIsAdmin(Boolean(esAdmin));
            } else {
                navigate("/")
            }
        }
    }, [user]);


    useEffect(() => {
        if (user && isAdmin) {
            const db = getDatabase();
            const budgetRef = ref(db, ruta + "contactMessages");

            onValue(budgetRef, (snapshot) => {
                try {
                    if (snapshot.exists()) {
                        const mensajes = snapshot.val();
                        const keys = Object.keys(mensajes);
                        const listaDeMensajes = keys.map(key => ({
                            key: key,
                            ...mensajes[key]
                        }));
                        setMessages(listaDeMensajes);
                    } else {
                        setMessages([]);
                    }
                } catch {
                    toast({
                        title: "Error",
                        description: "Hubo un error al obtener los datos.",
                        status: "error",
                        duration: 3000,
                        isClosable: false,
                    });
                } finally {
                    setLoading(false);
                }
            }, (error) => {
                toast({
                    title: "Error",
                    description: "Hubo un error al comunicarse al servidor.",
                    status: "error",
                    duration: 3000,
                    isClosable: false,
                });
                console.error(error);
                setLoading(false);
            });
        }
    }, [isAdmin]);

    const handleCloseAddModal = () => {
        setShowAddModal(false)
    }

    const onSeeMensaje = (Mensaje) => {
        setMensaje(Mensaje)
        handleConfirmSee(Mensaje)
        setShowAddModal(true)
    };

    const handleConfirmSee = (Mensaje) => {
        const db = getDatabase(firebase);
        const budgetRef = ref(db, `${ruta}contactMessages/${Mensaje.key}`);

        update(budgetRef, { ...Mensaje, visto: true }).then(() => {
            setMensaje({ ...Mensaje, visto: true })
        }).catch((error) => {
            console.error("Error", error);
            toast({
                title: "Error",
                description: "Hubo un error al visualizar el mensaje.",
                status: "error",
                duration: 3000,
                isClosable: false,
            });
        });
    };

    const handleContestado = () => {
        const db = getDatabase(firebase);
        const budgetRef = ref(db, `${ruta}contactMessages/${mensaje.key}`);

        update(budgetRef, { ...mensaje, contestado: !mensaje.contestado, contestadoPor: user.email }).then(() => {
            toast({
                title: !mensaje.contestado ? "Contestado" : "No Contestado",
                description: !mensaje.contestado ? 'El mensaje se marcó como "Contestado"' : 'El mensaje se marcó como "No Contestado"',
                status: "success",
                duration: 3000,
                isClosable: false,
            });
            setMensaje(null)
            handleCloseAddModal()
        }).catch((error) => {
            console.error("Error", error);
            toast({
                title: "Error",
                description: "Hubo un error al marcar el mensaje como contestado.",
                status: "error",
                duration: 3000,
                isClosable: false,
            });
            setMensaje(null)
            handleCloseAddModal()
        });
    }

    const handleNoLeido = () => {
        const db = getDatabase(firebase);
        const budgetRef = ref(db, `${ruta}contactMessages/${mensaje.key}`);

        update(budgetRef, { ...mensaje, visto: false, vistoPor: user.email }).then(() => {
            toast({
                title: "Marcado",
                description: 'El mensaje se marcó como "No Leido"',
                status: "success",
                duration: 3000,
                isClosable: false,
            });
            setMensaje(null)
            handleCloseAddModal()
        }).catch((error) => {
            console.error("Error", error);
            toast({
                title: "Error",
                description: "Hubo un error al marcar el mensaje como leído.",
                status: "error",
                duration: 3000,
                isClosable: false,
            });
            setMensaje(null)
            handleCloseAddModal()
        });
    }

    return (
        <Box p={6} maxW="1024px" mx="auto">
            <Stack direction={['column', 'row']} spacing='24px' m={4}>
                <Heading as="h2" mb={6}>Mensajes recibidos</Heading>
                <Spacer />
                <Tooltip label='Volver'>
                    <IconButton
                        colorScheme="yellow"
                        isRound
                        as={Link}
                        to="/"
                        aria-label='Volver'
                        icon={<FontAwesomeIcon icon={faCaretLeft} />}
                    />
                </Tooltip>
            </Stack>
            {loading &&
                <Flex width="100%" direction="column" justifyContent="center" alignContent="center" alignItems="center">
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>
            }
            {messages && messages.length > 0 ?
                <TableContainer>
                    <Table variant='striped' colorScheme='teal'>
                        <Thead>
                            <Tr>
                                <Th textAlign="center">Sujeto</Th>
                                <Th textAlign="center">Email</Th>
                                <Th textAlign="center">Telefono</Th>
                                <Th textAlign="center">Visto / Contestado</Th>
                                <Th isNumeric>Accion</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {messages.map((Mensaje, index) => (
                                <Tr key={index}>
                                    <Td textAlign="center">{Mensaje.clientName}</Td>
                                    <Td textAlign="center">{Mensaje.clientEmail}</Td>
                                    <Td textAlign="center">{Mensaje.clientPhone}</Td>
                                    <Td textAlign="center">
                                        <Tooltip label={Mensaje.visto ? Mensaje.contestado ? "Visto y contestado" : "Visto pero no contestado" : Mensaje.contestado ? "No visto pero contestado" : "No visto, no contestado"}>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                colorScheme={Mensaje.visto ? Mensaje.contestado ? "green" : "teal" : Mensaje.contestado ? "orange" : "red"}
                                                aria-label='Done'
                                                fontSize='20px'
                                                icon={<FontAwesomeIcon icon={Mensaje.visto ? Mensaje.contestado ? faCheckDouble : faCheck : Mensaje.contestado ? faMinus : faXmark} />}
                                            />
                                        </Tooltip>
                                    </Td>
                                    <Td isNumeric>
                                        <Tooltip label="Ver Mensaje">
                                            <IconButton
                                                onClick={() => onSeeMensaje(Mensaje)}
                                                isRound
                                                colorScheme='green'
                                                variant='outline'
                                                aria-label={`eliminar`}
                                                icon={<FontAwesomeIcon icon={faEye} />}
                                            />
                                        </Tooltip>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                : (
                    !loading &&
                    <>
                        <Text textAlign={"center"} mb={6}>No existen mensajes</Text>
                    </>
                )}

            <Modal isOpen={showAddModal} onClose={handleCloseAddModal} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        {mensaje &&
                            <Box p={6} maxW="800px" mx="auto">
                                <Stack direction="row" spacing="4" display={{ md: "flex" }} m={4}>
                                    <Heading>Mensaje</Heading>
                                    <Spacer />
                                    <Tooltip label="Marcar como No Leido" align="end">
                                        <IconButton icon={<FontAwesomeIcon icon={faEnvelope} />} isRound colorScheme='teal' onClick={handleNoLeido} aria-label='Marcar No Leido' />
                                    </Tooltip>
                                    <Tooltip label={mensaje.contestado ? "Marcar como No Contestado" : "Marcar como Contestado"} align="end">
                                        <IconButton icon={<FontAwesomeIcon icon={mensaje.contestado ? faCircle : faCircleCheck} />} isRound colorScheme='green' onClick={handleContestado} aria-label={mensaje.contestado ? "Marcar como No Contestado" : "Marcar como Contestado"} />
                                    </Tooltip>
                                </Stack>
                                <Grid templateColumns="1fr 2fr" gap={4}>
                                    <Text fontWeight="bold">Sujeto:</Text>
                                    <Text align="end">{mensaje.clientName}</Text>

                                    <Text fontWeight="bold">Email:</Text>
                                    <Text align="end">{mensaje.clientEmail}</Text>

                                    <Text fontWeight="bold">Telefono:</Text>
                                    <Text align="end">{mensaje.clientPhone}</Text>

                                    <Text fontWeight="bold">Mensaje:</Text>
                                    <Text align="end">{mensaje.clientMessage}</Text>
                                </Grid>
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Messages;
